<div class="container">
  <mat-card>
    <h3>
      {{ isCodeSentViaWpp ? 'Enviamos o token para o seu Whatsapp' : title }}
    </h3>
    <form (ngSubmit)="onSubmit()" *ngIf="!isLoading; else loading">
      <mat-form-field appearance="outline">
        <mat-label>Código</mat-label>
        <input
          #codeInput
          matInput
          type="text"
          [formControl]="code"
          inputmode="numeric"
          maxlength="6"
          required
        />
        <mat-error *ngIf="code.invalid">{{ getErrorMessage() }}</mat-error>
      </mat-form-field>
      <div class="actions--grid">
        <a
          mat-button
          class="first-action"
          color="primary"
          tabindex="0"
          (click)="goToAskCode()"
          (keypress)="linkPress($event)"
        >
          <img src="assets/svg/login/sms.svg" alt="" />
          {{
            isOTPenabled && !isCodeSentViaWpp
              ? 'Receber por SMS/E-mail'
              : 'Reenviar código'
          }}
        </a>
        <button
          *ngIf="isWhatsappButtonEnabled"
          mat-button
          class="second-action"
          type="button"
          color="primary"
          title=""
          (click)="onHandleWpp()"
        >
          <img src="assets/svg/login/wpp.svg" alt="" />
          Receber por WhatsApp
        </button>
        <button
          mat-raised-button
          class="main-button"
          color="primary"
          type="submit"
          title="Confirmar"
          [disabled]="isLoading"
        >
          Continuar
        </button>
        <button
          mat-button
          type="button"
          color="primary"
          title="Voltar"
          (click)="back()"
        >
          Voltar
        </button>
      </div>
    </form>
    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>
  </mat-card>
  <div class="warning">
    <mat-icon>info</mat-icon>
    <p class="no-margin">
      {{
        isOTPenabled && !isCodeSentViaWpp
          ? authTexts.TOKEN_INFO_OTP
          : authTexts.TOKEN_INFO
      }}
    </p>
  </div>
  <ng-container *ngIf="isOTPenabled && !isCodeSentViaWpp">
    <h3>Dúvidas sobre o Token</h3>
    <mat-accordion class="FAQ">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >1 - Onde obter o código do Token para acessar sua
            conta?</mat-panel-title
          >
        </mat-expansion-panel-header>
        <p>Para obter o código do Token você precisa:</p>

        <ul type="none">
          <li>1) Baixar o aplicativo Brasilprev no seu celular;</li>
          <li>2) Ativar o seu token.</li>
        </ul>

        <ol>
          <li>Abra o aplicativo brasilprev;</li>
          <li>Em cima do seu cpf, terá a opção "token";</li>
          <li>
            Digite o código (token) que aparece no visor do aplicativo quando
            solicitado no acesso ao site.
          </li>
        </ol>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >2 - Não estou recebendo SMS para ativar o token. O que devo fazer?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Caso não tenha recebido o SMS para ativar o seu token, clique em
          "Reenviar código" ou "Receber por WhatsApp" e o novo envio será
          realizado para o número cadastrado na Brasilprev.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>3 - Por que utilizar o token?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Para aumentar ainda mais a segurança do seu investimento, a Brasilprev
          solicita além da sua senha, a sequência numérica (Token) para entrar
          na sua conta pelo site.
        </p>

        <p>Para ter o Token você precisa:</p>

        <ol>
          <li>Baixar o aplicativo Brasilprev no seu celular;</li>
          <li>Ativar o seu token.</li>
        </ol>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            4 - Como instalar o App Brasilprev?</mat-panel-title
          >
        </mat-expansion-panel-header>
        <p>
          Para instalar o aplicativo da Brasilprev, acesse a App store ou Play
          store do seu celular e baixe o Brasilprev.
        </p>

        <p>
          Em seguida, você deverá prosseguir com a ativação do seu token. Basta
          fazer o login que o token será vinculado automaticamente ao seu
          aparelho.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>
