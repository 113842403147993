import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserData } from '@app/core/auth/user.model';
import { CookieService } from '@app/shared/service/cookie.service';
import { WindowService } from '@app/shared/service/window.service';
import {
  CONSENT_PERMISSION,
  PERMISSION_LABEL
} from './modules/consents/services/opin-consent/opin-consent.enum';
import {
  ConsentDTO,
  Resource
} from './modules/consents/services/opin-consent/opin-consent.model';
import { OpinConsentService } from './modules/consents/services/opin-consent/opin-consent.service';
import {
  Choice,
  ComponentData,
  MappedConsents,
  ResourcePermission,
  User
} from './open-insurance.model';
import { OpenInsuranceIcons } from './shared/constants/icons';
import {
  CONSENTS_TRANSMITTER_ROUTE,
  OPEN_INSURANCE_ROUTE,
  OPEN_INSURANCE_ROUTES
} from './shared/constants/routes';

@Injectable({
  providedIn: 'root'
})
export class OpenInsuranceService {
  constructor(
    protected readonly route: Router,
    private readonly windowService: WindowService,
    protected readonly cookieService: CookieService,
    protected readonly opinConsentService: OpinConsentService
  ) {
    const storagedCommandOnCookies = this.cookieService.get(
      this.COOKIE_COMMAND_ID_KEY
    );

    if (!storagedCommandOnCookies) return;

    this.setCommandID(storagedCommandOnCookies);
  }

  commandID: string;

  private readonly COOKIE_COMMAND_ID_KEY = 'OPIN_COMMAND_ID';

  get isOpinJourney(): boolean {
    return !!this.commandID;
  }

  private getChoices(permissions: ResourcePermission[]): Choice[] {
    return permissions.map((permission) => ({
      status: true,
      name: CONSENT_PERMISSION[permission],
      label: PERMISSION_LABEL[CONSENT_PERMISSION[permission]]
    }));
  }

  private getResource(
    resource: Resource[],
    icon: OpenInsuranceIcons,
    title: string
  ): ComponentData[] {
    return resource.map((plan) => {
      const planName = `plan_${plan.id}`;

      const componentData: ComponentData = {
        icon,
        title,
        id: plan.id,
        name: planName,
        canToggle: true,
        status: plan.status,
        subtitle: plan.label,
        details: plan.details,
        certificateId: plan.certificateId,
        choices: this.getChoices(plan.permissions)
      };

      return componentData;
    });
  }

  private getPersonalDataComponent(consent: ConsentDTO): ComponentData {
    const componentData: ComponentData = {
      id: 'personal_data',
      icon: 'DOCUMENT_PAPER',
      name: 'personal_data',
      title: 'Dados cadastrais',
      certificateId: 'personal_data',
      status: consent.personalData.status,
      choices: this.getChoices(consent.personalData.permissions),
      canToggle: false,
      details: consent.personalData.details
    };

    return componentData;
  }

  setCommandID(commandID: string): void {
    this.commandID = commandID;

    this.cookieService.set(this.COOKIE_COMMAND_ID_KEY, commandID, 10);
  }

  getUser(): User {
    const storagedUserName = sessionStorage.getItem(
      `profile: ${UserData.Name}`
    );
    const storagedUserCPF = sessionStorage.getItem(`profile: ${UserData.Cpf}`);

    if (!storagedUserName || !storagedUserCPF) {
      throw new Error(
        'Informações insuficientes do usuário: nome e/ou CPF não encontrados no armazenamento local.'
      );
    }

    return {
      nome: storagedUserName,
      cpf: storagedUserCPF
    };
  }

  getMappedConsent(consent: ConsentDTO): MappedConsents {
    const mappedConsent: MappedConsents = {
      personalData: this.getPersonalDataComponent(consent),
      personResources: this.getResource(
        consent.personResources,
        'ADMIN_PANEL_SETTINGS',
        'Planos de previdência'
      ),
      lifePensionResources: this.getResource(
        consent.lifePensionResources,
        'REQUEST_QUOTE',
        'Planos de previdência'
      ),
      pensionPlanResources: this.getResource(
        consent.pensionPlanResources,
        'ADMIN_PANEL_SETTINGS',
        'Planos de previdência (cobertura de risco)'
      )
    };

    return mappedConsent;
  }

  redirectToStartTransmissionJourney(): void {
    this.route.navigateByUrl(
      `${OPEN_INSURANCE_ROUTE}/${OPEN_INSURANCE_ROUTES[CONSENTS_TRANSMITTER_ROUTE].HOME}`
    );
  }

  async refuseConsentByAuthenticationError(
    token_autorizacao: string
  ): Promise<void> {
    const refusedCommand = await this.opinConsentService.refuseConsent(
      this.commandID,
      token_autorizacao
    );

    setTimeout(() => {
      this.windowService.redirectToExternalURL(
        refusedCommand.redirect.redirectTo
      );
    }, 3000);
  }
}
