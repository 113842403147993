import { Injectable } from '@angular/core';

import { PlanSummary } from '@app/home/statement.model';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import {
  AvailableInvestmentProfitability,
  Funds,
  IncomeReserveProjection,
  IncomeTax,
  InvestmentProfitability,
  PlanData,
  PlanInfo,
  PlanObjective,
  PlanTracking,
  RiskCoverage,
  Transactions,
  bodyUpdatePlan
} from './plan.model';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  constructor(private apollo: Apollo) {}

  readonly storagePlanPrefix = 'plan: ';
  readonly storageHomePlansPrefix = 'home plans: ';

  public getCssClass(objective: string): string {
    switch (objective) {
      case PlanObjective.RETIREMENT:
        return 'retirement';
      case PlanObjective.KIDS_FUTURE:
        return 'kids-future';
      case PlanObjective.SUCCESSION:
        return 'succession';
      case PlanObjective.EMERGENCY_RESERVE:
        return 'emergency-reserve';
      case PlanObjective.GOODS_ACQUISITION:
        return 'goods-acquisition';
      default:
        return 'save-money';
    }
  }
  public getObjectiveImage(objective: string): string {
    switch (objective) {
      case PlanObjective.RETIREMENT:
        return '/assets/retirement.png';
      case PlanObjective.KIDS_FUTURE:
        return '/assets/kids-future.png';
      case PlanObjective.SUCCESSION:
        return '/assets/save-money.png';
      case PlanObjective.EMERGENCY_RESERVE:
        return '/assets/save-money.png';
      case PlanObjective.GOODS_ACQUISITION:
        return '/assets/buy-house.png';
      default:
        return '/assets/save-money.png';
    }
  }

  public getSummaryBalance(
    registration: string,
    initDate: string,
    endDate: string
  ) {
    return this.apollo
      .use('statement')
      .watchQuery<any>({
        query: gql`
          query SummaryBalanceStatement(
            $registration: String!
            $initDate: String!
            $endDate: String!
          ) {
            summaryBalanceStatement(
              registration: $registration
              initDate: $initDate
              endDate: $endDate
            ) {
              totalRelease
              totalReleaseParticipant
              totalReleaseCompany
              supplementaryReleases {
                totalRelease
                totalReleaseParticipant
                totalReleaseCompany
              }
            }
          }
        `,
        variables: {
          registration,
          initDate,
          endDate
        }
      })
      .valueChanges.pipe(map((result) => result.data));
  }

  public getIncomeTax(year: string) {
    return this.apollo
      .use('incomeTax')
      .watchQuery<IncomeTax>({
        query: gql`
          query IncomeTaxStatement($year: String!) {
            incomeTaxStatement(year: $year) {
              cpf
              year
              link
            }
          }
        `,
        variables: {
          year
        }
      })
      .valueChanges.pipe(map((result) => result.data.incomeTaxStatement));
  }

  public getInvestmentProfitability(
    registration: string,
    initDate: string,
    endDate: string
  ) {
    return this.apollo
      .use('statement')
      .watchQuery<InvestmentProfitability>({
        query: gql`
          query InvestmentProfitabilityStatement(
            $registration: String!
            $initDate: String!
            $endDate: String!
          ) {
            investmentProfitabilityStatement(
              registration: $registration
              initDate: $initDate
              endDate: $endDate
            ) {
              averagePercentageCurrentMonth
              averagePercentagePastMonth
              averagePercentageAccumulatedYear
              averagePercentageAccumulated12
              averagePercentageAccumulated24
              averagePercentageAccumulated36
              averagePercentageAccumulated48
              averagePercentageAccumulated60
              investmentList {
                investmentId
                category
                name
                description
                investmentTaf
                investmentCnpj
                percentageCurrentMonth
                percentagePastMonth
                percentageAccumulatedYear
                percentageAccumulated12
                percentageAccumulated24
                percentageAccumulated36
                percentageAccumulated48
                percentageAccumulated60
                riskGrade
              }
            }
          }
        `,
        variables: {
          registration,
          initDate,
          endDate
        }
      })
      .valueChanges.pipe(
        map((result) => result.data.investmentProfitabilityStatement)
      );
  }

  public getAvailableInvestmentProfitability(
    registration: string,
    initDate: string,
    endDate: string
  ) {
    return this.apollo
      .use('statement')
      .watchQuery<AvailableInvestmentProfitability>({
        query: gql`
          query AvailableInvestmentProfitabilityStatement(
            $registration: String!
            $initDate: String!
            $endDate: String!
          ) {
            availableInvestmentProfitabilityStatement(
              registration: $registration
              initDate: $initDate
              endDate: $endDate
            ) {
              averagePercentageCurrentMonth
              averagePercentagePastMonth
              averagePercentageAccumulatedYear
              averagePercentageAccumulated12
              averagePercentageAccumulated24
              averagePercentageAccumulated36
              averagePercentageAccumulated48
              averagePercentageAccumulated60
              availableInvestmentList {
                investmentId
                category
                name
                description
                investmentTaf
                investmentCnpj
                percentageCurrentMonth
                percentagePastMonth
                percentageAccumulatedYear
                percentageAccumulated12
                percentageAccumulated24
                percentageAccumulated36
                percentageAccumulated48
                percentageAccumulated60
                riskGrade
              }
            }
          }
        `,
        variables: {
          registration,
          initDate,
          endDate
        }
      })
      .valueChanges.pipe(
        map((result) => result.data.availableInvestmentProfitabilityStatement)
      );
  }

  public getRiskCoverage(
    registration: string,
    initDate: string,
    endDate: string
  ) {
    return this.apollo
      .use('statement')
      .watchQuery<RiskCoverage>({
        query: gql`
          query RiskCoverageStatement(
            $registration: String!
            $initDate: String!
            $endDate: String!
          ) {
            riskCoverageStatement(
              registration: $registration
              initDate: $initDate
              endDate: $endDate
            ) {
              name
              prize
              amount
              frequencyContribution
            }
          }
        `,
        variables: {
          registration,
          initDate,
          endDate
        }
      })
      .valueChanges.pipe(map((result) => result.data.riskCoverageStatement));
  }

  public getPlanInfo(registration: string, initDate: string, endDate: string) {
    return this.apollo
      .use('statement')
      .watchQuery<PlanInfo>({
        query: gql`
          query PlanInfoStatement(
            $registration: String!
            $initDate: String!
            $endDate: String!
          ) {
            planInfoStatement(
              registration: $registration
              initDate: $initDate
              endDate: $endDate
            ) {
              concessionDate
              incomeType
              taxationType
              taf
            }
          }
        `,
        variables: {
          registration,
          initDate,
          endDate
        }
      })
      .valueChanges.pipe(map((result) => result.data.planInfoStatement));
  }

  public getFunds(registration: string, initDate: string, endDate: string) {
    return this.apollo
      .use('statement')
      .watchQuery<Funds>({
        query: gql`
          query FundsStatement(
            $registration: String!
            $initDate: String!
            $endDate: String!
          ) {
            fundsStatement(
              registration: $registration
              initDate: $initDate
              endDate: $endDate
            ) {
              id
              name
              balance
              idInvestment
              percentage
              balanceYield
              riskGrade
              category
              urlInformation
              idTipoBloqueio
              nomeTipoBloqueio
              saldoBloqueado
              saldoDisponivel
            }
          }
        `,
        variables: {
          registration,
          initDate,
          endDate
        }
      })
      .valueChanges.pipe(map((result) => result.data.fundsStatement));
  }

  public getTransactions(
    registration: string,
    initDate: string,
    endDate: string
  ) {
    return this.apollo
      .use('statement')
      .watchQuery<Transactions>({
        query: gql`
          query TransactionsStatement(
            $registration: String!
            $initDate: String!
            $endDate: String!
          ) {
            transactionsStatement(
              registration: $registration
              initDate: $initDate
              endDate: $endDate
            ) {
              name
              date
              total
              totalParticipant
              totalCompany
              totalCharges,
              isRisckCoverageItem
              listInvestments {
                name
                valueParticipant
                valueCompany
                amount
              }
              listCharges {
                name
                amount
              }
              listRiskCoverage {
                name,
                amount
              }
            }
          }
        `,
        variables: {
          registration,
          initDate,
          endDate
        }
      })
      .valueChanges.pipe(map((result) => result.data.transactionsStatement));
  }

  public getPlanTracking(registration: string, year: string) {
    return this.apollo
      .use('statement')
      .watchQuery<PlanTracking>({
        query: gql`
          query PlanTrackingStatement($registration: String!, $year: String!) {
            planTrackingStatement(registration: $registration, year: $year) {
              year
              yearBalance
              yearProfitability
              yearYield
              monthsType {
                month
                monthBalance
                monthProfitability
                monthYield
                referenceDate
              }
            }
          }
        `,
        variables: {
          registration,
          year
        }
      })
      .valueChanges.pipe(map((result) => result.data.planTrackingStatement));
  }

  public getIncomeReserveProjection(registration: string) {
    return this.apollo
      .use('statement')
      .watchQuery<IncomeReserveProjection>({
        query: gql`
          query IncomeReserveProjectionStatement($registration: String!) {
            incomeReserveProjectionStatement(registration: $registration) {
              incomeValue
              reserveValue
            }
          }
        `,
        variables: {
          registration
        }
      })
      .valueChanges.pipe(
        map((result) => result.data.incomeReserveProjectionStatement)
      );
  }

  public getRegulatoryStatement(registration: string) {
    return this.apollo
      .use('statement')
      .watchQuery<any>({
        query: gql`
          query RegulatoryStatement($registration: String!) {
            regulatoryStatement(registration: $registration) {
              status
              url
            }
          }
        `,
        variables: {
          registration
        }
      })
      .valueChanges.pipe(map((result) => result.data.regulatoryStatement));
  }

  updatePlanGraphQL(input: bodyUpdatePlan): Observable<any> {
    return this.apollo
      .use('statement')
      .mutate<any>({
        mutation: gql`
          mutation editPlan($input: body) {
            editPlan(input: $input) {
              status
              detail
              error_code
            }
          }
        `,
        variables: {
          input
        }
      })
      .pipe(pluck('data', 'editPlan'));
  }

  public getPlanData(planData: PlanData): string {
    const value = sessionStorage.getItem(
      `${this.storagePlanPrefix}${planData}`
    );
    return value ? value : null;
  }

  public setPlanData(key: PlanData, value: string): void {
    sessionStorage.setItem(`${this.storagePlanPrefix}${key}`, value);
  }

  public cleanPlanData(): void {
    for (let i = 0; i < sessionStorage.length; i++) {
      if (
        sessionStorage.key(i).substring(0, this.storagePlanPrefix.length) ===
        this.storagePlanPrefix
      ) {
        sessionStorage.removeItem(sessionStorage.key(i));
      }
    }
  }

  public setHomePlans(plans: PlanSummary[]) {
    sessionStorage.setItem(this.storageHomePlansPrefix, JSON.stringify(plans));
  }

  public getHomePlans(): PlanSummary[] {
    return JSON.parse(sessionStorage.getItem(this.storageHomePlansPrefix));
  }

  public deleteHomePlans(): void {
    sessionStorage.removeItem(this.storageHomePlansPrefix);
  }
}
