import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { AuthData } from '@app/core/auth/auth.model';
import { Success } from '@app/core/authentication/authentication';
import { UrlHistoryService } from '@app/url-history.service';

@Component({
  selector: 'app-check-code',
  templateUrl: './check-code.component.html',
  styleUrls: ['./check-code.component.scss']
})
export class CheckCodeComponent implements OnInit {
  contactMethod: string;
  isLoading: boolean;
  isOTPenabled = this.authService.getOTPstate();

  constructor(
    private authService: AuthService,
    private urlHistoryService: UrlHistoryService
  ) {}

  ngOnInit(): void {
    this.contactMethod =
      this.authService.getAuthData(AuthData.ContactMethod) === 'sms'
        ? 'celular'
        : 'email';

    this.back = this.back.bind(this);
  }

  public handleCodeVerified(event: { code: string }): void {
    this.isLoading = true;
    this.authService.login(event.code).subscribe((response: Success) => {
      if (response.success === false) {
        this.isLoading = false;
      }
    });
  }

  public back(): void {
    this.urlHistoryService.back();
  }
}
