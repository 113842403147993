export enum AuthData {
  ChosenChannel = 'chosenChannel',
  ContactMethod = 'contactMethod',
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  AuthToken = 'authToken',
  SecurityCode = 'securityCode',
  ExpiresIn = 'expiresIn',
  CodeBB = 'codeBB',
  IsOTPenabled = 'isOTPenabled'
}

export class UrlRedirectParam {
  code?: string;
}

export enum TokenPrefix {
  Bearer = 'Bearer ',
  Oauth2 = 'OAUTH2 '
}

export class UrlItem {
  url: string;
  prefix: string;
}

export const TokenPrefixUrlList = [
  {
    url: '/checkup/',
    prefix: 'Bearer'
  },
  {
    url: '/desacumulacao/',
    prefix: 'Bearer'
  },
  {
    url: '/cerberus-auth/',
    prefix: 'Bearer'
  },
  {
    url: '/resgate/',
    prefix: 'Oauth2'
  },
  {
    url: '/extrato/',
    prefix: 'Oauth2'
  },

  {
    url: '/v1/planos/atualizar',
    prefix: 'Oauth2'
  },
  {
    url: '/v2/upload',
    prefix: 'Oauth2'
  },
  {
    url: '/v1/planos/objetivo',
    prefix: 'Oauth2'
  },
  {
    url: '/v2/planos/listar',
    prefix: 'Oauth2'
  },
  {
    url: '/v2/autenticacao/perfil',
    prefix: 'Oauth2'
  },
  {
    url: '/v1/planos/informederendimento',
    prefix: 'Oauth2'
  },
  {
    url: '/v2/autenticacao/atualizarcadastro',
    prefix: 'Oauth2'
  }
];
