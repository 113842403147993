import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CpfUtils } from '@app/core/validators/cpf.validator';
import { PasswordUtils } from '@app/core/validators/loginPassword.validator';
import { InputUtils } from '@app/core/validators/matches.validator';
import { PhoneUtils } from '@app/core/validators/phone.validator';
import { PrivacyDirective } from '@app/shared/directive/privacy/privacy.directive';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgChartsModule } from 'ng2-charts';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask
} from 'ngx-mask';
import { InvestmentComparatorComponent } from '../plan/funds-page/investment-comparator/investment-comparator.component';
import { RegistrationCardComponent } from '../shared/components/registration-card/registration-card.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { BpcComparisionChartModule } from './bpc-comparision-chart/bpc-comparision-chart.module';
import { AccessibilityServiceChannelLinkComponent } from './components/accessibility-service-channel-link/accessibility-service-channel-link.component';
import { BannerComponent } from './components/banner/banner.component';
import { CallCenterComponent } from './components/call-center/call-center.component';
import { CardWithNotificationsComponent } from './components/card-with-notifications/card-with-notifications.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { ChartComponent } from './components/charts/chart/chart.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { MainChartComponent } from './components/charts/main-chart/main-chart.component';
import { EmptyPlanListComponent } from './components/empty-plan-list/empty-plan-list.component';
import { ExtHeaderComponent } from './components/ext-header/ext-header.component';
import { ExtraContributionDistributionComponent } from './components/extra-contribution-distribution/extra-contribution-distribution.component';
import { ContactsComponent } from './components/footer/contacts/contacts.component';
import { FooterComponent } from './components/footer/footer.component';
import { ExpansionPanelComponent } from './components/header/expansion-panel/expansion-panel.component';
import { HeaderNavigationComponent } from './components/header/header-navigation/header-navigation.component';
import { HeaderComponent } from './components/header/header.component';
import { ProfileMenuComponent } from './components/header/profile-menu/profile-menu.component';
import { BrazilianHelpCenterComponent } from './components/help-center-carousel/brazilian-help-center/brazilian-help-center.component';
import { ForeingHelpCenterComponent } from './components/help-center-carousel/foreing-help-center/foreing-help-center.component';
import { FormDirectCallComponent } from './components/help-center-carousel/form-direct-call/form-direct-call.component';
import { FormHelpCenterComponent } from './components/help-center-carousel/form-help-center/form-help-center.component';
import { HelpCenterCarouselComponent } from './components/help-center-carousel/help-center-carousel.component';
import { HighlightCardComponent } from './components/highlight-card/highlight-card.component';
import { IncomeTaxTableComponent } from './components/income-tax-table/income-tax-table.component';
import { InfoListComponent } from './components/info-list/info-list.component';
import { LinkCardsComponent } from './components/link-cards/link-cards.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ProfitabilityComponent } from './components/profitability/profitability.component';
import { RateAppModalComponent } from './components/rate-app-modal/rate-app-modal.component';
import { RiskIconComponent } from './components/risk-icon/risk-icon.component';
import { RiskComponent } from './components/risk/risk.component';
import { StatementBannerComponent } from './components/statement-banner/statement-banner.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { DesignSystemModule } from './design-system/design-system.module';
import { CapitalizeFirstLetter } from './pipe/capitalize-first-letter.pipe';
import { SortByFieldPipe } from './pipe/sort.pipe';
import { ToPositivePipe } from './pipe/to-positive.pipe';
import { ScreenUtils } from './utils/screenUtils';

registerLocaleData(ptBr);

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {
  thousandSeparator: '.'
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    NgChartsModule,
    DesignSystemModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    PrivacyDirective,
    SubHeaderComponent,
    ChartComponent,
    ToPositivePipe,
    SortByFieldPipe,
    DoughnutChartComponent,
    LineChartComponent,
    HighlightCardComponent,
    ProfitabilityComponent,
    RegistrationCardComponent,
    BarChartComponent,
    InfoListComponent,
    EmptyPlanListComponent,
    IncomeTaxTableComponent,
    MainChartComponent,
    ExtraContributionDistributionComponent,
    CallCenterComponent,
    RiskIconComponent,
    HelpCenterCarouselComponent,
    ForeingHelpCenterComponent,
    BrazilianHelpCenterComponent,
    FormHelpCenterComponent,
    FormDirectCallComponent,
    BrazilianHelpCenterComponent,
    CapitalizeFirstLetter,
    RiskComponent,
    HeaderComponent,
    HeaderNavigationComponent,
    ExpansionPanelComponent,
    ProfileMenuComponent,
    StatementBannerComponent,
    BannerComponent,
    FooterComponent,
    ContactsComponent,
    AccessibilityServiceChannelLinkComponent,
    InvestmentComparatorComponent,
    LinkCardsComponent,
    ExtHeaderComponent,
    CardWithNotificationsComponent,
    RateAppModalComponent,
    PaginatorComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    DesignSystemModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    PrivacyDirective,
    SubHeaderComponent,
    ChartComponent,
    DoughnutChartComponent,
    NgChartsModule,
    ToPositivePipe,
    SortByFieldPipe,
    LineChartComponent,
    HighlightCardComponent,
    ProfitabilityComponent,
    RegistrationCardComponent,
    MatIconModule,
    BarChartComponent,
    MainChartComponent,
    BpcComparisionChartModule,
    ClipboardModule,
    InfoListComponent,
    EmptyPlanListComponent,
    IncomeTaxTableComponent,
    ExtraContributionDistributionComponent,
    CallCenterComponent,
    RiskIconComponent,
    RiskComponent,
    HelpCenterCarouselComponent,
    ForeingHelpCenterComponent,
    BrazilianHelpCenterComponent,
    FormDirectCallComponent,
    BrazilianHelpCenterComponent,
    CapitalizeFirstLetter,
    HeaderComponent,
    HeaderNavigationComponent,
    StatementBannerComponent,
    BannerComponent,
    FooterComponent,
    InvestmentComparatorComponent,
    LinkCardsComponent,
    ExtHeaderComponent,
    CardWithNotificationsComponent,
    RateAppModalComponent,
    AccessibilityServiceChannelLinkComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    PaginatorComponent
  ],
  providers: [
    provideEnvironmentNgxMask(options),
    CpfUtils,
    PhoneUtils,
    ScreenUtils,
    PasswordUtils,
    InputUtils,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    }
  ]
})
export class SharedModule {}
