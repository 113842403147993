import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthData,
  TokenPrefix,
  TokenPrefixUrlList,
  UrlItem
} from '@app/core/auth/auth.model';
import { AuthResult, UserData } from '@app/core/auth/user.model';
import {
  AuthenticationService,
  ErrorResponseError_code as ErrorCode,
  LoginCodeWithCPFSource,
  SetupSource,
  Success,
  SuccessAuth,
  SuccessGetContacts,
  SuccessTokenRefresh
} from '@app/core/authentication';
import {
  SuccessResponseLoginStatus as AuthResponse,
  Data19Investor_profile_status,
  SuccessResponseProfileV2Status as ProfileStatus,
  UpdateUserV2
} from '@app/core/bpApi';
import {
  AlteracaoSenhaRequest,
  AtualizacaoCadastroContato,
  AtualizacaoCadastroEndereco,
  AtualizacaoCadastroRequest,
  AtualizacaoCadastroResponse,
  ConsultarOptinWhatsappResponse,
  HttpErro,
  HttpErroTipo,
  LoginRequest,
  LoginResponse,
  LoginService,
  PerfilResponse,
  PreLoginRequest,
  PreLoginResponse,
  RenovarLoginRequest,
  RenovarLoginResponse,
  SolicitarMFARequest
} from '@app/core/login';
import { NotificationService } from '@app/core/notification/notification.service';
import { Plan, PlanTraditional, Service } from '@app/core/validation-bff';
import { CpfUtils } from '@app/core/validators/cpf.validator';
import { OpenInsuranceService } from '@app/open-insurance/open-insurance.service';
import { CookieService } from '@app/shared/service/cookie.service';
import { ScreenUtils } from '@app/shared/utils/screenUtils';
import { ValidationLoginPlansData } from '@app/validation/validations.model';
import { Apollo } from 'apollo-angular';
import { isDate } from 'date-fns';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {
  AuthErrorMessage,
  AuthenticationByJourneyUrl,
  Channel,
  ErrorType,
  LocalStorageId,
  NewPasswordSuccess,
  Profile,
  ProfileData,
  SuccessProfileAndPlansResponse,
  TargetJourneyUrl,
  WindowAppIdentifier
} from './auth.model';
import { OsService } from './os.service';
import { PlanAuthService } from './plan.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  preLoginToken: string;
  authorization: string;

  private _isAuthenticationByOpenInsurance = false;
  private _isAuthenticateByCheckup = false;

  targetJourneyUrl?: TargetJourneyUrl;

  private destroy$ = new Subject();

  constructor(
    private osService: OsService,
    private router: Router,
    private apollo: Apollo,
    private cpfUtils: CpfUtils,
    private cookie: CookieService,
    private validationBff: Service,
    private screenUtils: ScreenUtils,
    private loginService: LoginService,
    private planService: PlanAuthService,
    private profileService: ProfileService,
    private notification: NotificationService,
    private bffAuthenticationService: AuthenticationService,
    private readonly openInsuranceService: OpenInsuranceService
  ) {
    if (this.getAuthData(AuthData.RefreshToken)) {
      this.loggedIn.next(true);
      this.headerEnabled.next(true);
    }
    if (this.planService.getValidationPlans()) {
      this.planUnsigned.next(true);
    }
    if (this.planService.getTradValidationPlans()) {
      this.planTradUnsigned.next(true);
    }
    if (this.getUserData(UserData.ProfileStatus) === ProfileStatus.VALID_USER) {
      this.profileUpdated.next(true);
    }
    this.privacyOn.next(this.getPrivacyState());
  }

  public profileUpdated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public planTradUnsigned: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public planUnsigned: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public headerEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private imageUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.getUserData(UserData.ImageUrl)
  );

  private privacyOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  private async handleRefuseConsentByAuthentication(): Promise<void> {
    if (this._isAuthenticationByOpenInsurance) {
      this.notification.showWarning(
        'Por motivos de segurança, o consentimento foi automaticamente cancelado, após 3 segundos iremos te redirecionar novamente para a instituição solicitante. Tente compartilhar novamente mais tarde',
        'Ok',
        {
          duration: 3500
        }
      );

      await this.openInsuranceService.refuseConsentByAuthenticationError(
        this.getToken()
      );
    }
  }

  get isProfileUpdated() {
    return this.profileUpdated.asObservable();
  }

  get isHeaderEnabled() {
    return this.headerEnabled.asObservable();
  }

  get isLoggedIn() {
    if (this.isApp()) {
      this.postAppMessage('ISLOGGEDIN');
    }
    return this.loggedIn.asObservable();
  }

  get isPlanTradUnsigned() {
    return this.planTradUnsigned.asObservable();
  }

  get isPlanUnsigned() {
    return this.planUnsigned.asObservable();
  }

  set isAuthenticationByOpenInsurance(value: boolean) {
    this._isAuthenticationByOpenInsurance = value;
  }

  set isAuthenticationByCheckup(value: boolean) {
    this._isAuthenticateByCheckup = value;
  }

  get isAuthenticationByCheckup() {
    return this._isAuthenticateByCheckup;
  }

  public isApp(): boolean {
    return window[WindowAppIdentifier] !== undefined;
  }
  public postAppMessage(message: string): void {
    return window[WindowAppIdentifier].postMessage(message);
  }

  public storeUser(successResponseProfile: Profile) {
    const status = successResponseProfile.status;
    const profile: ProfileData = successResponseProfile.data;
    for (const key of Object.keys(profile)) {
      const originalValue: any = profile[key];
      let value: string;
      if (
        originalValue === null ||
        originalValue === undefined ||
        originalValue === ''
      ) {
        value = 'Não Informado';
      } else if (typeof originalValue === 'string') {
        value = originalValue;
      } else if (isDate(originalValue)) {
        value = originalValue.toJSON();
      } else {
        value = originalValue.toString();
      }
      this.setUserData(key as UserData, value);
    }
    this.setUserImage(profile.image_url);

    const name: RegExpMatchArray = profile.name.match(/\b\w/g) || [''];
    const nameInitials: string = (
      (name.shift() || '') + (name.pop() || '')
    ).toUpperCase();
    this.setUserData(UserData.NameInitials, nameInitials);
    this.setUserData(UserData.ProfileStatus, status);
    this.setUserData(UserData.CurrentOS, this.osService.identifyOS());
  }

  public getUserData(userData: UserData): string {
    const value = sessionStorage.getItem(`profile: ${userData}`);

    if (value === 'Não Informado' || !value) return null;

    return value;
  }

  public getUserAge() {
    const today = new Date();
    const birthDate = new Date(this.getUserData(UserData.BirthDate));
    const monthDiff = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  public getUserTelephone() {
    const phone = this.getUserData(UserData.Telephone);
    const ddd = this.getUserData(UserData.Ddd);
    const phoneHasDddIncluded = phone.length > 9;

    if (phoneHasDddIncluded) {
      return phone;
    }

    return ddd + phone;
  }

  public setLoginData(cpf: string) {
    localStorage.setItem(LocalStorageId.LOGIN, cpf);
  }

  public getLoginData(): string {
    const value = localStorage.getItem(LocalStorageId.LOGIN);
    return value ? value : '';
  }

  public deleteLoginData() {
    localStorage.removeItem(LocalStorageId.LOGIN);
  }

  public setUserData(key: UserData, value: string): void {
    sessionStorage.setItem(`profile: ${key}`, value);
  }

  public deleteUserData(userData: UserData): void {
    sessionStorage.removeItem(`profile: ${userData}`);
  }

  public getUserImage(): Observable<string> {
    return this.imageUrl
      .asObservable()
      .pipe(map((url) => (url === 'Não Informado' ? null : url)));
  }

  public setUserImage(url: string): void {
    this.imageUrl.next(url);
  }

  public getProfileBff(authorization: string): Observable<Profile> {
    const authToken = authorization
      ? authorization
      : this.getToken(TokenPrefix.Oauth2);

    return this.loginService
      .perfil(authToken)
      .pipe(
        map((profileResponse: PerfilResponse) =>
          this.profileService.parseToProfileV2(profileResponse)
        )
      );
  }

  public parseToUpdateProfile(user: UpdateUserV2) {
    return AtualizacaoCadastroRequest.fromJS({
      nome: user.nome,
      codigo: user.codigo,
      dataNascimento: user.data_nascimento,
      contato: AtualizacaoCadastroContato.fromJS({
        ddd: user.ddd,
        email: user.email,
        telefone: user.telefone
      }),
      endereco: AtualizacaoCadastroEndereco.fromJS({
        bairro: user.bairro,
        cep: user.bairro,
        cidade: user.cidade,
        complemento: user.complemento,
        estado: user.estado,
        numero: user.numero,
        rua: user.rua
      })
    });
  }

  public updateProfileAuthentication(
    userUpdatedData: UpdateUserV2
  ): Observable<AtualizacaoCadastroResponse> {
    return this.loginService
      .atualizarCadastro(
        this.getToken(),
        this.parseToUpdateProfile(userUpdatedData)
      )
      .pipe(
        catchError((error: HttpErro) => {
          if (error instanceof HttpErro) {
            switch (error.tipo) {
              case HttpErroTipo.CPF_INVALIDO:
                this.notification.showError('Ops! CPF não existe.');
                break;
              case HttpErroTipo.CODIGO_INVALIDO:
                this.notification.showError(
                  'Ops! Sua sessão expirou. Faça login novamente'
                );
                this.logout();
                break;
              case HttpErroTipo.EMAIL_INVALIDO:
                this.notification.showError('Ops! E-mail inválido.');
                break;
              case HttpErroTipo.TELEFONE_INVALIDO:
                this.notification.showError('Ops! Telefone inválido.');
                break;
              default:
                this.notification.showError('Ops! ocorreu um erro inesperado!');
                break;
            }
            return EMPTY;
          } else {
            return throwError(error);
          }
        })
      );
  }

  preLogin(cpf: string, virtualPassword: string): Observable<PreLoginResponse> {
    const cpfDigits = this.cpfUtils.toDigits(cpf);

    return this.loginService
      .preLogin(PreLoginRequest.fromJS({ cpf, virtualPassword }))
      .pipe(
        take(1),
        tap((response: PreLoginResponse) => {
          this.preLoginToken = response.secretHash;
          this.setUserData(UserData.Cpf, cpfDigits);
          this.setUserContacts(
            SuccessGetContacts.fromJS({
              email: response.dadosContato.email,
              telephone: response.dadosContato.telefone
            })
          );
        }),
        tap((res: PreLoginResponse) => {
          if (res.codigoOTP) {
            this.setAuthData(AuthData.IsOTPenabled, 'true');
          }
        }),
        catchError((error) => {
          if (
            error.tipo === HttpErroTipo.CREDENCIAIS_INVALIDAS ||
            error.tipo === HttpErroTipo.ATRIBUTO_INVALIDO_OU_NAO_INFORMADO
          ) {
            this.notification.showError(
              AuthErrorMessage.INVALID_CREDENTIALS,
              'Ok',
              { duration: 0 }
            );
            this.handleRefuseConsentByAuthentication();
          } else if (error.tipo === HttpErroTipo.SENHA_BLOQUEADA) {
            this.notification.showWarning(
              AuthErrorMessage.BLOCKED_PASSWORD,
              'Ok',
              {
                duration: 0
              }
            );
            this.handleRefuseConsentByAuthentication();
          } else if (
            error.tipo === HttpErroTipo.DADOS_USUARIO_NAO_ENCONTRADOS
          ) {
            this.notification.showError(
              AuthErrorMessage.MISSING_USER_DATA,
              'Ok',
              {
                duration: 0
              }
            );
            this.router.navigate(['auth/error']);
          } else if (
            error.tipo === HttpErroTipo.ENTRE_EM_CONTATO_COM_SUPORTE ||
            error.tipo === HttpErroTipo.DADOS_CONTATO_NAO_ENCONTRADOS
          ) {
            this.notification.showWarning(
              AuthErrorMessage.MISSING_CONTACTS,
              'Ok',
              {
                duration: 0
              }
            );
            this.router.navigate(['auth/error']);
          } else {
            if (error.mensagem) {
              this.notification.showWarning(error.mensagem, 'Ok', {
                duration: 0
              });
            }
            this.router.navigate(['auth/error']);
          }
          return EMPTY;
        })
      );
  }

  public login(code: string): Observable<LoginResponse | Success> {
    const cpf = this.getUserData(UserData.Cpf).toString();
    return this.loginService
      .login(
        this.preLoginToken,
        LoginRequest.fromJS({
          canalAcesso: this.getPlatformSource(),
          codigoMfa: code,
          cpf
        })
      )
      .pipe(
        take(1),
        tap((authResult: LoginResponse) => {
          this.loggedIn.next(true);
          this.handleAuthResponse(
            SuccessAuth.fromJS({
              oauth2_token: authResult.token,
              oauth2_refresh_token: authResult.refreshToken,
              oauth2_expires_in: authResult.tempoParaExpirar,
              oauth2_tokens_timestamp: authResult.timestamp,
              status: authResult.status,
              cpf,
              data: authResult.dadosLogin
            })
          );
        }),
        catchError((error) => {
          const isOTPenabled = this.getAuthData(AuthData.IsOTPenabled);
          if (this.isApp()) {
            this.postAppMessage(ErrorType.LOGIN_ERROR);
          }
          if (error.tipo === HttpErroTipo.CODIGO_INVALIDO) {
            this.notification.showWarning(
              isOTPenabled
                ? AuthErrorMessage.INVALID_OTP
                : AuthErrorMessage.INVALID_CODE,
              'Ok',
              {
                duration: 0
              }
            );
            if (!isOTPenabled) {
              this.router.navigate(['auth/ask-code']);
            }
          } else if (error.tipo === HttpErroTipo.CODIGO_BLOQUEADO) {
            this.notification.showError(AuthErrorMessage.BLOCKED_CODE, 'Ok', {
              duration: 0
            });
            this.router.navigate(['auth']);
          } else if (error.tipo === HttpErroTipo.CREDENCIAIS_INVALIDAS) {
            this.notification.showError(
              AuthErrorMessage.INVALID_CREDENTIALS,
              'Ok',
              {
                duration: 0
              }
            );
            this.router.navigate(['auth']);
          } else {
            this.notification.showError(AuthErrorMessage.GENERIC, 'Ok', {
              duration: 0
            });
          }
          return of({ success: false } as Success);
        })
      );
  }

  handleAuthResponse(authResult: SuccessAuth): void {
    this.storeAuthResult(authResult);
    this.getProfileBff(authResult.oauth2_token)
      .pipe(
        take(1),
        catchError((error) => {
          if (
            error.error_code === ErrorCode.INVALID_CREDENTIALS ||
            error.error_code === ErrorCode.FIELDS_ERROR
          ) {
            this.notification.showError(
              AuthErrorMessage.INVALID_CREDENTIALS,
              'Ok',
              { duration: 0 }
            );
          } else if (error.error_code === ErrorCode.BLOCKED_PASSWORD) {
            this.notification.showWarning(
              AuthErrorMessage.BLOCKED_PASSWORD,
              'Ok',
              {
                duration: 0
              }
            );
          } else if (error.error_code === ErrorCode.MISSING_USER_DATA) {
            this.notification.showError(
              AuthErrorMessage.MISSING_USER_DATA,
              'Ok',
              {
                duration: 0
              }
            );
            this.router.navigate(['auth/error']);
          } else {
            return throwError(error);
          }
          return EMPTY;
        }),
        mergeMap((profile: Profile) =>
          this.getValidationLoginPlansData().pipe(
            map((validationData: ValidationLoginPlansData) => ({
              profile,
              validationData
            }))
          )
        )
      )
      .subscribe((response: SuccessProfileAndPlansResponse) => {
        this.storeUser(response.profile);

        this.planUnsigned.next(response.validationData.isSomePlanUnsigned);
        this.planTradUnsigned.next(
          response.validationData.isSomeTradPlanUnsigned
        );

        if (this._isAuthenticationByOpenInsurance) {
          this.openInsuranceService.redirectToStartTransmissionJourney();
          return;
        }
        if (response.validationData.isSomePlanUnsigned) {
          this.planService.setValidationPlans(response.validationData.plans);
        }
        if (response.validationData.isSomeTradPlanUnsigned) {
          this.planService.setTradValidationPlans(
            response.validationData.tradPlans
          );
        }
        if (this.targetJourneyUrl) {
          this.router.navigateByUrl(this.targetJourneyUrl);
          return;
        }
        if (authResult.status === AuthResponse.MAKE_REGISTRATION_UPDATE) {
          this.profileUpdated.next(false);
          this.router.navigate(['/profile/onboarding']);
        } else if (authResult.status === AuthResponse.LOGGED_IN) {
          if (
            !authResult.data.flagPlanosAtualizados &&
            authResult.data.flagPossuiPlanos
          ) {
            this.router.navigate(['/plan/onboarding']);
            return;
          }
          if (
            response.profile.data.investor_profile_status ===
            Data19Investor_profile_status.PENDING_DEFINITION
          ) {
            this.router.navigate(['/profile/investor/pending']);
            return;
          } else {
            this.profileUpdated.next(true);
            this.router.navigate(['/home']);
          }
        }
      });
  }

  public getValidationLoginPlansData(): Observable<ValidationLoginPlansData> {
    let isSomePlanUnsigned: boolean;
    let plans: Plan[];

    return this.validationBff.plansList(this.getToken()).pipe(
      take(1),
      switchMap((validationPlans: Plan[]) => {
        plans = validationPlans;
        isSomePlanUnsigned = Boolean(
          plans.find((plan: Plan) => plan.isEligible)
        );
        return this.validationBff.planTraditionalList(this.getToken());
      }),
      catchError(() => this.validationBff.planTraditionalList(this.getToken())),
      map(
        (tradPlans: PlanTraditional[]) =>
          ({
            isSomePlanUnsigned,
            plans,
            isSomeTradPlanUnsigned: Boolean(tradPlans.length),
            tradPlans
          } as ValidationLoginPlansData)
      ),
      catchError(() =>
        of({
          isSomePlanUnsigned: false,
          isSomeTradPlanUnsigned: false
        } as ValidationLoginPlansData)
      )
    );
  }

  updateUserData() {
    return this.getProfileBff(this.getToken()).pipe(
      map((profile) => {
        this.storeUser(profile);
        return profile;
      })
    );
  }

  public isPrivacyOn(): Observable<boolean> {
    return this.privacyOn.asObservable();
  }

  public setPrivacyState(state: boolean) {
    sessionStorage.setItem('privacyState', state.toString());
    this.privacyOn.next(state);
  }

  public getPrivacyState(): boolean {
    return sessionStorage.getItem('privacyState') === 'true';
  }

  public setUserContacts = (userContacts: SuccessGetContacts): void => {
    if (userContacts.email) {
      this.setUserData(UserData.Email, userContacts.email);
    } else {
      this.deleteUserData(UserData.Email);
    }
    if (userContacts.telephone) {
      this.setUserData(UserData.Telephone, userContacts.telephone);
    } else {
      this.deleteUserData(UserData.Telephone);
    }
  };

  public getToken(tokenPrefix?: TokenPrefix): string {
    const authToken = this.cookie.get(AuthData.AuthToken);
    return authToken
      ? `${tokenPrefix ? tokenPrefix : ''}${authToken}`
      : authToken;
  }

  public getTokenWithPrefix(url: string): string {
    const urlItem = TokenPrefixUrlList.find((item: UrlItem) =>
      url.includes(item.url)
    );

    return urlItem
      ? this.getToken(TokenPrefix[urlItem.prefix])
      : this.getToken();
  }

  public askCode(channel: Channel) {
    return this.loginService
      .solicitarMfa(
        SolicitarMFARequest.fromJS({
          cpf: this.getUserData(UserData.Cpf),
          canal: channel
        })
      )
      .pipe(
        take(1),
        catchError((error) => {
          if (error.tipo === HttpErroTipo.TELEFONE_INVALIDO_SMS) {
            this.notification.showError(
              AuthErrorMessage.INVALID_PHONE_SMS,
              'Ok',
              { duration: 0 }
            );
            this.router.navigate(['auth/error']);
            return of(error);
          }
          if (error.tipo === HttpErroTipo.CODIGO_BLOQUEADO) {
            this.notification.showError(AuthErrorMessage.BLOCKED_CODE, 'Ok', {
              duration: 0
            });
            this.router.navigate(['auth']);
            return of(error);
          }
          if (error.statusCode === 403) {
            return EMPTY;
          }
          return throwError(error);
        })
      );
  }

  public changePassword(newPassword: string): Observable<Success> {
    const request = AlteracaoSenhaRequest.fromJS({
      codigoMfa: this.getAuthData(AuthData.SecurityCode),
      cpf: this.getUserData(UserData.Cpf),
      novaSenha: newPassword
    });

    return this.loginService.alterarSenha(request).pipe(
      map(() => Success.fromJS({ success: true })),
      catchError((error: HttpErro) => {
        if (error.tipo === HttpErroTipo.CODIGO_INVALIDO) {
          this.notification.showWarning(AuthErrorMessage.INVALID_CODE, 'Ok', {
            duration: 0
          });
          return of({ success: false, resendCode: true } as NewPasswordSuccess);
        } else if (error.tipo === HttpErroTipo.CODIGO_BLOQUEADO) {
          this.notification.showError(AuthErrorMessage.BLOCKED_CODE, 'Ok', {
            duration: 0
          });
          return of({ success: false, resendCode: true } as NewPasswordSuccess);
        } else if (
          error.tipo === HttpErroTipo.ATRIBUTO_INVALIDO_OU_NAO_INFORMADO
        ) {
          this.notification.showError(AuthErrorMessage.BIRTH_DATE_ERROR, 'Ok', {
            duration: 0
          });
          return of({
            success: false,
            resendCode: false
          } as NewPasswordSuccess);
        }
        return throwError(error);
      })
    );
  }

  public revokeTokens(token: string): Observable<void> {
    return this.bffAuthenticationService.revoke(token).pipe(first());
  }

  public redirectToTradValidation(): void {
    this.router.navigate(['validation', 'traditional']);
  }

  public redirectToValidation(): void {
    this.router.navigate(['validation', 'list']);
  }

  public redirectToOnBoarding(): void {
    this.router.navigate(['profile', 'onboarding']);
  }

  logoutForOpenInsuranceWithCode(code: string) {
    this.clearApplicationCache();

    this.router.navigateByUrl(`/auth/opin?${code}`);
  }

  handleAuthenticationByJourney(url: string) {
    this.setTargetJourneyUrl(url);

    if (this.targetJourneyUrl) {
      this.isLoggedIn
        .pipe(
          first(),
          map((isLoggedIn: boolean) => {
            if (!isLoggedIn) {
              this.clearApplicationCache();
              this.router.navigateByUrl(`/auth`);
            }
            this.router.navigateByUrl(this.targetJourneyUrl);
          })
        )
        .subscribe();
    }
  }

  setTargetJourneyUrl(url: string) {
    if (url.includes(AuthenticationByJourneyUrl.CHECKUP)) {
      this.targetJourneyUrl = TargetJourneyUrl.CHECKUP;
    } else if (url.includes(AuthenticationByJourneyUrl.DECUMULATION)) {
      this.targetJourneyUrl = TargetJourneyUrl.DECUMULATION;
    }
  }

  authenticateAndGoToCheckup() {
    this.isAuthenticationByCheckup = true;
    this.isLoggedIn
      .pipe(
        first(),
        map((isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.clearApplicationCache();
            this.router.navigateByUrl(`/auth`);
          }
          this.router.navigateByUrl(`/checkup/my-checkups`);
        })
      )
      .subscribe();
  }

  public logout(): void {
    const token = this.getToken(TokenPrefix.Bearer);
    this.clearApplicationCache();
    this.router.navigate(['auth']);
    if (token) {
      this.revokeTokens(token)
        .pipe(catchError(() => EMPTY))
        .subscribe();
    }
  }

  public clearApplicationCache(): void {
    sessionStorage.clear();
    this.cookie.delete(AuthData.AuthToken);
    this.cookie.delete(AuthData.RefreshToken);
    this.cleanInMemoryCache();
    this.loggedIn.next(false);
  }

  public refreshToken(): Observable<RenovarLoginResponse> {
    return this.loginService
      .renovar({
        refreshToken: this.cookie.get(AuthData.RefreshToken)
      } as RenovarLoginRequest)
      .pipe(
        tap((authResponse: SuccessTokenRefresh) => {
          this.setAuthTokensData(authResponse);
        }),
        catchError(() => {
          this.notification.showWarning(AuthErrorMessage.EXPIRED_TOKEN, 'Ok', {
            duration: 0
          });
          this.logout();
          return EMPTY;
        })
      );
  }

  public storeAuthResult(authResult: SuccessAuth) {
    sessionStorage.setItem('code', authResult.data.codigo);

    sessionStorage.setItem(
      'flagUpdatedRegister',
      authResult.data.flagRegistroAtualizado.toString()
    );

    this.setAuthTokensData({
      token: authResult.oauth2_token,
      refreshToken: authResult.oauth2_refresh_token,
      tempoParaExpirar: authResult.oauth2_expires_in,
      timestamp: authResult.oauth2_tokens_timestamp
    } as RenovarLoginResponse);
  }

  public getAuthResult(): AuthResult {
    return {
      username: sessionStorage.getItem('username'),
      code: sessionStorage.getItem('code'),
      flagUpdatedRegister: sessionStorage.getItem('flagUpdatedRegister'),
      authToken: this.cookie.get(AuthData.AuthToken),
      refreshToken: this.cookie.get(AuthData.RefreshToken)
    };
  }

  public setAuthTokensData(authResponse: RenovarLoginResponse): void {
    const expiresInMinutes = authResponse.tempoParaExpirar / 60;

    this.cookie.set(AuthData.AuthToken, authResponse.token, expiresInMinutes);

    this.cookie.set(
      AuthData.RefreshToken,
      authResponse.refreshToken,
      expiresInMinutes + 5
    );

    this.setAuthData(AuthData.RefreshToken, authResponse.refreshToken);
  }

  public setAuthData(key: AuthData, value: string): void {
    sessionStorage.setItem(`Authentication: ${key}`, value);
  }

  public getAuthData(authData: AuthData): string {
    const value = sessionStorage.getItem(`Authentication: ${authData}`);
    return value ? value : null;
  }

  public removeAuthData(authData: AuthData): void {
    sessionStorage.removeItem(`Authentication: ${authData}`);
  }

  public getOTPstate(): boolean {
    return this.getAuthData(AuthData.IsOTPenabled) === 'true';
  }

  public denyOTP(): void {
    this.removeAuthData(AuthData.IsOTPenabled);
  }

  private async cleanInMemoryCache(): Promise<void> {
    await this.apollo.use('withdraw').client.clearStore();
    await this.apollo.use('statement').client.clearStore();
    await this.apollo.use('incomeTax').client.clearStore();
  }

  public getPlatformSource(): SetupSource | LoginCodeWithCPFSource {
    const localStoragePlatform = SetupSource[localStorage.getItem('platform')];
    if (localStoragePlatform) {
      return localStoragePlatform;
    } else {
      return this.screenUtils.isMobile ? SetupSource.MOBILE : SetupSource.WEB;
    }
  }

  public checkWhatsappOptin(): Observable<ConsultarOptinWhatsappResponse> {
    return this.loginService
      .consultarOptinWhatsapp(this.getUserData(UserData.Cpf))
      .pipe(
        take(1),
        catchError(() => EMPTY)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
