import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/authentication';
import { ConsultarOptinWhatsappResponse } from '@app/core/login';
import { NotificationService } from '@app/core/notification/notification.service';
import { ScreenUtils } from '@app/shared/utils/screenUtils';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { AuthTexts, Channel } from '../auth.model';
import { AuthService } from '../auth.service';
import { WppModalComponent } from '../wpp-modal/wpp-modal.component';

@Component({
  selector: 'app-check-twofa-token',
  templateUrl: './check-twofa-token.component.html',
  styleUrls: ['../auth-components.scss']
})
export class CheckTwofaTokenComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() text: string;
  @Input() isLoading: boolean;
  @Input() verifyCode = true;
  @Input() isOTPenabled = false;
  @Input() isWhatsappButtonEnabled: boolean = this.isWppEnabled(
    environment.features.whatsapp_mfa_button
  );
  @Input() back: () => void;
  @Output() handleWpp = new EventEmitter<void>();
  @Output() hasCode = new EventEmitter<{ code: string }>();
  isWhatsappMessagesAllowed: boolean;
  isCodeSentViaWpp: boolean;
  authTexts = AuthTexts;

  code = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.pattern('^[0-9]*$')
  ]);

  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private screenUtils: ScreenUtils
  ) {}

  ngOnInit(): void {
    this.checkWhatsappOptin();
    this.startListening();
  }

  ngOnDestroy(): void {
    this.stopListening();
  }

  public isWppEnabled(environmentVar: string): boolean {
    return environmentVar === 'true';
  }

  public checkWhatsappOptin(): void {
    if (this.isWhatsappButtonEnabled) {
      this.isLoading = true;
      this.authService
        .checkWhatsappOptin()
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (response: ConsultarOptinWhatsappResponse) =>
            (this.isWhatsappMessagesAllowed = response.permitirMensagens)
        );
    }
  }

  public getErrorMessage(): string {
    let message: string;

    if (this.code.hasError('required')) {
      message = 'Campo obrigatório';
    }

    if (this.code.hasError('minlength') || this.code.hasError('maxlength')) {
      message = 'O campo deve conter 6 caracteres';
    }

    if (this.code.hasError('pattern')) {
      message = 'O campo deve conter apenas números';
    }

    return message;
  }

  public onSubmit(): void {
    if (this.code.valid) {
      this.hasCode.emit({ code: this.code.value });
    }
  }

  public onHandleWpp(): void {
    this.verifyAndDenyOTP();
    if (this.isWhatsappMessagesAllowed) {
      this.isLoading = true;
      this.authService
        .askCode(Channel.WPP)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((response: ErrorResponse | null) => {
          if (response === null) {
            this.isCodeSentViaWpp = true;
            this.notificationService.showSuccess(
              'Código enviado para o seu whatsapp',
              'Ok',
              { duration: 5000 }
            );
          }
        });
    } else {
      this.dialog.open(WppModalComponent, {
        width: this.screenUtils.isMobile ? '90%' : '23.625rem'
      });
    }
    this.handleWpp.emit();
  }

  public resetControl(): void {
    this.code.reset('');
    this.code.setErrors(null);
  }

  public goToAskCode(): void {
    this.verifyAndDenyOTP();
    this.router.navigate(['auth', 'ask-code']);
  }

  public verifyAndDenyOTP(): void {
    if (this.isOTPenabled) {
      this.authService.denyOTP();
    }
  }

  public linkPress(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.goToAskCode();
    }
  }

  public startListening(): void {
    if (this.authService.isApp()) {
      window.addEventListener('message', (event) => {
        if (typeof event.data === 'string') {
          const token = event.data;
          this.code.patchValue(token);
        }
      });
    }
  }

  public stopListening(): void {
    window.removeEventListener('message', () => {});
  }
}
