<h3 class="title">Movimentação</h3>
<mat-card class="transfers">
  <mat-card-header> </mat-card-header>
  <mat-card-content>
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="years.length - 1"
      (selectedTabChange)="nextYear(years[$event.index])">
      <mat-tab *ngFor="let year of years; let i = index" [label]="year.toString()" isActive="active">
        <ng-template matTabContent>
          <ng-container *ngIf="planTransfers$ | async as transfers; else loading">
            <ul *ngIf="transfers.length !== 1 || transfers[0].total !== 0" class="transfers">
              <ng-container *ngFor="let transfer of transfers; let i = index">
                <li>
                  <app-transfer-item [transferItem]="transfer"></app-transfer-item>
                </li>
              </ng-container>
            </ul>
            <h4 *ngIf="transfers.length === 1 && transfers[0].total === 0">
              {{ messageNoMoviments }}
            </h4>
          </ng-container>
          <ng-template #loading>
            <mat-spinner></mat-spinner>
          </ng-template>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>