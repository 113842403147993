import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-transfer-item',
  templateUrl: './transfer-item.component.html',
  styleUrls: ['./transfer-item.component.scss']
})
export class TransferItemComponent implements OnInit {
  @Input() transferItem;
  public isDetailsVisible = false;
  isRiskCoverage: boolean;

  ngOnInit(): void {
    this.isRiskCoverage = this.transferItem?.isRisckCoverageItem;
  }

  toggleDetails() {
    this.isDetailsVisible = !this.isDetailsVisible;
  }
}
