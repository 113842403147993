<span class="transfer-item">
  <ng-container
    *ngIf="'Transaction' === this.transferItem.__typename; else consolidated"
  >
    <mat-icon svgIcon="blackMoneyIn" *ngIf="transferItem.total >= 0"></mat-icon>
    <mat-icon svgIcon="moneyOut" *ngIf="transferItem.total < 0"></mat-icon>
    <span class="transfer-name" [title]="transferItem.nome">
      {{ transferItem?.name }}
    </span>
    <span class="date" *ngIf="transferItem.date">
      {{ transferItem?.date }}
    </span>
    <strong
      class="total"
      [ngClass]="{ 'money-in': transferItem.total >= 0 }"
      [appPrivacy]="transferItem.total | toPositive | currency"
    ></strong>
  </ng-container>
  <ng-template #consolidated>
    <mat-icon>trending_up</mat-icon>
    <span class="transfer-name-consolidated"
      >Evolução de saldo e rendimento durante o ano.</span
    >
    <span class="badge">novo</span>
  </ng-template>
  <mat-icon
    (click)="toggleDetails()"
    title="Detalhe movimentacao"
    class="arrow-down"
    [ngClass]="isDetailsVisible ? 'arrow-up' : ''"
    >keyboard_arrow_down</mat-icon
  >
  <div class="fund-detail" [ngClass]="isDetailsVisible ? 'show-detail' : ''">
    @if(!isRiskCoverage) {
    <ng-container *ngFor="let fund of transferItem.listInvestments">
      <span class="fund">{{ fund?.name }} </span>
      <span [appPrivacy]="fund.amount | toPositive | currency"></span>
    </ng-container>
    }
    <ng-container *ngIf="transferItem.totalCharges !== 0">
      <ng-container *ngFor="let charge of transferItem.listCharges">
        <span class="fund">{{ charge?.name }} </span>
        <span class="charge" [appPrivacy]="charge.amount | currency"></span>
      </ng-container>
    </ng-container>
    <div class="details-content">
      @if(isRiskCoverage) {
      <div
        class="risk-detail"
        *ngFor="let risk of transferItem?.listRiskCoverage"
      >
        <span class="name">
          <span>{{ risk?.name }}</span>
        </span>
        <span class="amount" [appPrivacy]="risk?.amount | currency"></span>
      </div>
      } @else {
      <ng-container
        *ngIf="
          'Transaction' === this.transferItem.__typename;
          else consolidatedDetail
        "
      >
        <div class="details-line">
          <p>Empresa</p>
          <p
            class="value"
            [appPrivacy]="transferItem.totalCompany | currency"
          ></p>
        </div>

        <div class="details-line">
          <p>Participante</p>
          <p
            class="value"
            [appPrivacy]="transferItem.totalParticipant | currency"
          ></p>
        </div>

        <div class="details-line">
          <p>Total</p>
          <p class="value" [appPrivacy]="transferItem.total | currency"></p>
        </div>
      </ng-container>
      <ng-template #consolidatedDetail>
        <app-consolidated-table></app-consolidated-table>
      </ng-template>
      }
    </div>
  </div>
</span>
