import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';

import designSystemSvgIcons from './design-system-svg-icons';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkStepperModule
  ],
  declarations: [],
  providers: [],
  exports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkStepperModule
  ]
})
export class AngularMaterialModule {
  public constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) {
    // add custom material icons
    matIconRegistry.addSvgIcon(
      'moneyIn',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/money-in.svg')
    );
    matIconRegistry.addSvgIcon(
      'blackMoneyIn',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/black-money-in.svg')
    );
    matIconRegistry.addSvgIcon(
      'moneyOut',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/money-out.svg')
    );
    matIconRegistry.addSvgIcon(
      'moneyGrow',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/money-grow.svg')
    );
    matIconRegistry.addSvgIcon(
      'arrow',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/foward.svg')
    );
    matIconRegistry.addSvgIcon(
      'lightBulb',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/light-bulb.svg')
    );
    matIconRegistry.addSvgIcon(
      'pdf',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/pdf.svg')
    );
    matIconRegistry.addSvgIcon(
      'regulation',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/regulamento.svg')
    );
    matIconRegistry.addSvgIcon(
      'backspace-outlined',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/backspace-outlined.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'checkup-icon',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/checkup-icon.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'evolution',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/evolution.svg')
    );

    designSystemSvgIcons.forEach((svgIcon) => {
      matIconRegistry.addSvgIcon(
        svgIcon.name,
        domSanitizer.bypassSecurityTrustResourceUrl(svgIcon.path)
      );
    });
  }
}
